/*
 * Bassplate
 * Boilerplate for Basscss with PostCSS
 * http://github.com/basscss/bassplate
 *
 * Remove or comment out unneeded modules and import your own files.
 * Adjust global media queries and custom properties below.
 *
 */


@import 'basscss-basic';

/* Basscss Modules */
@import 'basscss-type-scale';
@import 'basscss-typography';
@import 'basscss-layout';
@import 'basscss-align';
@import 'basscss-margin';
@import 'basscss-padding';
@import 'basscss-grid';
@import 'basscss-flexbox';
@import 'basscss-position';
@import 'basscss-border';
@import 'basscss-hide';

@import 'layout.css';


/* Optional Modules (not included in Basscss by default) */
/*

@import 'basscss-addons/modules/responsive-margin';
@import 'basscss-addons/modules/responsive-padding';

@import 'basscss-addons/modules/media-object';
@import 'basscss-addons/modules/all';
@import 'basscss-addons/modules/forms';
@import 'basscss-addons/modules/input-range';
@import 'basscss-addons/modules/progress';

@import 'basscss-addons/modules/btn';
@import 'basscss-addons/modules/btn-primary';
@import 'basscss-addons/modules/btn-outline';
@import 'basscss-addons/modules/btn-sizes';

@import 'basscss-addons/modules/colors';
@import 'basscss-addons/modules/background-colors';
@import 'basscss-addons/modules/border-colors';
@import 'basscss-addons/modules/darken';
@import 'basscss-addons/modules/lighten';
*/
@import 'basscss-addons/modules/background-images';


/* Custom media queries */
/*
@custom-media --breakpoint-sm (min-width: 40em);
@custom-media --breakpoint-md (min-width: 52em);
@custom-media --breakpoint-lg (min-width: 64em);
*/


/* Custom properties */

:root {
  --h1: 2rem;
  --h2: 1.5rem;
  --h3: 1.25rem;
  --h4: 1rem;
  --h5: .875rem;
  --h6: .75rem;

  --bold-font-weight: bold;

  --space-1: .5rem;
  --space-2: 1rem;
  --space-3: 2rem;
  --space-4: 4rem;

  --border-width: 1px;
  --border-radius: 3px;
}

a {
    -webkit-border-radius: 5px;
            border-radius: 5px;
}

:fullscreen a {
    display: flex
}
