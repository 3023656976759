a {
  text-decoration: none;
  color: #666;

  transition: color 0.5s ease;
}

a:hover {
  color: #ff3300;
}

.active {
  font-weight: bolder;
  color: #ff3300;
  opacity: 0.7;
}

.serif { font-family: Palatino }

.caps0 { text-transform: uppercase; }
.caps1 { text-transform: uppercase; letter-spacing: .1em; }

.capt {
  text-transform: capitalize;
}

.bold400 {
  font-weight: 400;
}

.fit {
  max-width: 100%;
  max-height: 100%;
}

.hover {
  transition: opacity ease 0.5s;
  opacity: 0.7;
}

.hover:hover {
  opacity: 1;
}

.img-zoom {
  overflow: hidden;
  display: block;
}

.img-zoom img {
  transition-property: all;
  transition-timing-function: cubic-bezier(.42, 0, .58, 1);
  transition-duration: 2s;
  transform: rotate(0) scale(1);
}

.img-zoom:hover img{
  transform: rotate(3deg) scale(1.1);

}

code, pre, samp, .mono {
  font-family: 'Roboto Mono', Menlo, monospace;
}

.h0 {
  font-size: 2.5rem;
  line-height: 1;
}

@media screen and (min-width: 48em) {
  .h0 { font-size: 5rem }
}

@media screen and (min-width: 80em) {
  .h0 { font-size: 6vw }
}

.v60 { min-height: 60vh }
.v80 { min-height: 80vh }
.v100 { min-height: 100vh }

.btn {
  font-family: inherit;
  font-size: .875rem;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
  margin: 0;
  padding: .75rem 1rem;
  border: 0;
  border-radius: 2px;
  color: #fff;
  background-color: #555;
  -webkit-appearance: none;
}

.btn:hover {
  box-shadow: inset 0 0 0 999px rgba(0, 0, 0, .375);
}

.white { color: #fff }
.black { color: #555 }
.blue  { color: #09d }
.dark-blue { color: #059 }
.grey {color: #666;}
.grey-light {color: #BCBABA;}
.orange {color: #ff3300;}
/*.frost { color: rgba(255, 255, 255, .875) }
*/
.bg-white { background-color: #fff }
.bg-black { background-color: #555 }
.bg-blue  { background-color: #09d }
.bg-dark-blue { background-color: #059 }
.bg-frost { background-color: rgba(255, 255, 255, .875) }

.bg-cover  { background-size: cover }
.bg-center { background-position: center }

.bd { border: 4px solid }
.bt { border-top: 4px solid }
.br { border-right: 4px solid }
.bb { border-bottom: 4px solid }
.bl { border-left: 4px solid }

.vignette {
  box-shadow: inset 0 0 128px rgba(0, 0, 0, .9);
 }

.plm__bg {
  background-image: url('assets/images/bg.jpg')
}

.plm__imgportfolio {
  height: 500px;
}

body {
  background-color: white;
  font-family: 'Lato', sans-serif;
  font-weight: 200;
}

main {
  padding-top: 75px;
}

header {
  position: fixed;
  border-bottom: 1px solid #F3F4EF;
  background-color: white;
  width: 100%;
  z-index: 100;
}


.slider {
  display: none;
}
